.about-wrap {
  /* height: 400px; */
  width: 100%;
  background: var(--bg);
}

.about {
  /* display: flex; */
  width: 85%;
  margin: 0 auto;
  height: 100%;
}

.about-img {
  /* width: 50%; */
  height: 100%;
  padding-top: 1.5rem;
}

.about-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-content {
  /* width: 50%; */
  padding: 1.5rem 0;
}

.about-content h1 {
  color: var(--purple);
  margin-bottom: 2rem;
}

.about-content p {
  font-size: 1rem;
  margin: 1.3rem 0;
  font-weight: 400;
}

@media screen and (min-width: 900px) {
  .about {
    display: flex;
  }

  .about-img,
  .about-content {
    width: 50%;
  }

  .about-img {
    padding-top: 0;
  }

  .about-content {
    padding: 1em;
  }
}

@media screen and (min-width: 1200px) {
  .about-wrap {
    height: 400px;
  }

  .about {
    display: flex;
    width: 80%;
  }

  .about-img,
  .about-content {
    width: 50%;
  }

  .about-img {
    padding-top: 0;
  }

  .about-content p {
    font-size: 1.3rem;
  }
}
