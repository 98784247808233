.subscribe-wrap {
  background: url("../../assets/img/gradient-background-2.png") no-repeat
    center/cover;
  width: 100%;
  padding: 2em 0;
  /* height: fit-content; */
}

.subscribe {
  width: 60%;
  max-width: 100%;
  margin: 0 auto;
  /* padding: 1rem 0; */
  color: var(--white);
  text-align: center;
}

.subscribe-content {
  /* spadding: 2rem 0 0 0; */
}
.subscribe-content h1 {
  font-size: 2rem;
  font-weight: 500;
}
.subscribe-content p {
  margin: 1em 0;
  /* padding: 0 9rem; */
}

.subscribe-form {
  margin-top: 1.5rem;
}
.subscribe-form form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-form input,
.subscribe-form button {
  padding: 0.4rem 0;
  border: none;
  border-radius: 0.15rem;
  outline: none;
  font-size: 0.9em;
}
.subscribe-form form input {
  width: 60%;
  padding: 1rem;
}

.subscribe-form form input::placeholder {
  /* font-family: var(); */
}
.subscribe-form form button {
  width: 15%;
  color: var(--white);
  background-color: var(--purple);
  font-weight: 300;
  margin-left: 0.2rem;
  padding: 1rem;
  cursor: pointer;
}

@media (min-width: 100px) {
  .subscribe {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    /* padding: 2rem 0; */
  }
  .subscribe-content h1 {
    font-size: 1.5rem;
    /* padding-bottom: 0.55rem; */
  }
  .suscribe-content p {
    margin-top: 2rem;
    /* padding: 0 2rem 0; */
  }

  .subscribe-form {
    width: 90%;
    margin: 0 auto;
  }
  .subscribe-form form {
    flex-direction: column;
  }

  .subscribe-form form input,
  .subscribe-form form button {
    width: 100%;
    margin-top: 0.55rem;
  }
}

@media (min-width: 500px) {
  .subscribe {
    width: 80%;
    max-width: 100%;
    margin: 0 auto;
  }
  .subscribe-form form {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .subscribe-wrap {
    padding: 2em 1em;
  }

  .subscribe-form form button {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .subscribe {
    width: 60%;
    max-width: 100%;
    margin: 0 auto;
    /* padding: 1rem 0; */
  }
  .subscribe-content h1 {
    font-size: 2rem;
  }
  .subscribe-form form {
    flex-direction: column;
  }
  .subscribe-form form button,
  .subscribe-form form input {
    width: 100%;
    /* padding: 0.40rem 1rem; */
  }
}

@media (min-width: 960px) {
  .subscribe {
    width: 70%;
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
    object-fit: scale-down;
  }

  .subscribe-form form {
    flex-direction: row;
  }
  .subscribe-form form input {
    width: 60%;
  }
  .subscribe-form form button {
    width: 15%;
  }
}

@media (min-width: 1200px) {
  .subscribe {
    width: 60%;
    max-width: 100%;
    margin: 0 auto;
    /* padding: 1rem 0; */
    object-fit: scale-down;
  }

  .subscribe-content h1 {
    font-size: 2.6rem;
  }

  .suscribe-content p {
    font-size: 1.3rem;
    text-align: justify;
  }

  .subscribe-form form {
    flex-direction: row;
  }
  .subscribe-form form input {
    width: 60%;
  }
  .subscribe-form form button {
    width: 15%;
  }
}
