.nav-section {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  padding: 1em 1.1em;
  z-index: 999;
}

.nav-section.black-bg {
  background: rgba(0, 0, 0, 0.6);
}

.nav-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.nav-logo img {
  width: 50%;
  object-fit: contain;
}

.nav-links {
  display: none;
  flex-direction: column;
  /* align-items: center; */
}

.nav-links.active {
  display: flex;
}

.nav-links ul {
  display: flex;
  flex-direction: column;
}

.nav-links ul li {
  margin: 1em 0;
}

.nav-links ul li a {
  color: var(--white);
  text-transform: capitalize;
  font-size: 1.2em;
  font-weight: 400;
}

.nav-get-app-btn {
  font-size: 1.2em;
  font-weight: 400;
  color: var(--white);
  border: 2px solid var(--white);
  border-radius: 8px;
  text-transform: capitalize;
  padding: 0.2em 1.5em;
  text-align: center;
}

.hamburger {
  position: absolute;
  top: 0.6em;
  right: 1.3em;
  color: var(--white);
  font-size: 1.5em;
  z-index: 999;
}

@media screen and (max-width: 600px) {
  .nav-links ul {
    align-items: center;
  }

  .nav-get-app-btn {
    width: 70%;
    margin: 1em auto;
  }
}

@media screen and (min-width: 768px) {
  .nav-logo {
    width: 70%;
  }
}

@media screen and (min-width: 900px) {
  .nav-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5em;
    padding-right: 5em;
  }

  .hamburger {
    display: none;
  }

  .nav-logo {
    width: 200px;
  }

  .nav-logo img {
    width: 100%;
    object-fit: contain;
  }

  .nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-links ul {
    flex-direction: row;
  }

  .nav-links ul li {
    margin: 0 0.9em;
  }
}
