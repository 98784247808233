.down-load-app {
  background: url("../../assets/img/gradient-background.png") no-repeat
    center/cover;
  color: var(--white);
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  /* padding: 2rem 0; */
}
.down-load-app h2 {
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 1em;
  /* width: 40%; */
  /* padding: 1rem 6rem 3rem; */
}

.down-load-app .app-store-link img {
  width: 40%;
  object-fit: contain;
}

@media (min-width: 375px) {
  .down-load-app h2 {
    font-size: 1.3em;
    /* padding: 2rem auto 1rem; */
    /* margin-top: 3rem; */
  }
  .down-load-app .app-store-link img {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .down-load-app {
    margin-top: 1em;
  }
}

@media (min-width: 768px) {
  /* .down-load-app h2 {
    padding: 2rem auto 1rem;
    margin-top: 3rem;
  } */
  .down-load-app .app-store-link img {
    width: 40%;
  }
}

@media (min-width: 900px) {
  .down-load-app h2 {
    font-size: 1.3em;
    font-weight: 600;
    text-align: center;
    width: 50%;
    /* padding: 4rem 10rem 3rem; */
  }

  .down-load-app .app-store-link img {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .down-load-app h2 {
    font-size: 2.3rem;
    font-weight: 600;
    text-align: center;
    width: 40%;
    /* padding: 4rem 25rem 3rem; */
  }
  .down-load-app .app-store-link img {
    width: 40%;
  }
}
