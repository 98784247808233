.blog-card {
  padding: 1em;
  /* max-width: 300px; */
}

.blog-card:hover {
  background: var(--light-gray);
  border-radius: 0.5em;
}

.blog-card-img img {
  width: 100%;
  height: 17em;
  object-fit: cover;
  border-radius: 0.5em;
}

.blog-card-tag {
  display: inline-block;
  background: var(--black);
  color: var(--white);
  padding: 0.5em;
  margin: 1em 0;
  border-radius: 0.5em;
  text-transform: capitalize;
}

.blog-card-title {
  font-size: 1.5em;
  text-transform: capitalize;
  margin-top: 1em;
}

.blog-card-date {
  display: inline-block;
  margin: 1em 0;
  text-transform: uppercase;
}

.blog-card-description p {
  font-size: 1.1em;
  line-height: 1.5;
}

.blog-card > a {
  display: block;
  margin: 1em 0;
  text-transform: capitalize;
}

.blog-card > a svg {
  margin-left: 0.5em;
}

@media screen and (min-width: 1200px) {
  .blog-card {
    max-width: 300px;
  }
}
