.top-section {
  position: relative;
  height: fit-content;
  padding-top: 1.5rem;
}

.top-section-mockup-img {
  display: flex;
  justify-content: center;
  width: 100%;
  /* position: absolute; */
  /* top: 2.4%; */
  /* left: 30%; */
  /* transform: translateX(40%); */
}

.top-section-mockup-img img {
  width: 100%;
  transform: translateX(-20%);
}

.eden-logo {
  display: none;
  position: absolute;
  bottom: -13%;
  right: 37.5%;
  width: 200px;
}

.eden-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bottom-section {
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
  height: 140px;
  background: var(--white);
}

.bottom-section-container {
  display: flex;
  justify-content: flex-end;
  width: 55%;
  margin: 0 auto;
}

.section-download-btns {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.section-download-btns a {
  width: 200px;
  height: 50px;
  /* margin: 5px; */
}

.section-download-btns a:nth-child(1) {
  margin-bottom: 5px;
}

.section-download-btns a img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 800px) {
  /* .top-section-mockup-img{
    height: 100%;
  } */
  .section-download-btns a:nth-child(2) {
    margin: 0.5em 0;
  }
}

@media screen and (min-width: 768px) {
  .top-section-mockup-img {
    width: 80%;
    transform: translateX(11%);
  }
}

@media screen and (min-width: 900px) {
  .top-section-mockup-img {
    width: 60%;
    transform: translateX(32%);
  }
}

@media screen and (min-width: 1200px) {
  .top-section {
    background: url("../../../assets/img/SectionTwo/section-two-big.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 680px;
  }

  .top-section-mockup-img {
    position: absolute;
    top: 2.4%;
    left: 30%;
    transform: translateX(-30%);
    width: 40%;
  }

  .eden-logo {
    display: block;
  }
}
