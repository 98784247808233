.download-btns {
  display: flex;
  flex-direction: column;
}

.download-btns a {
  width: 150px;
  height: 60px;
}

.download-btns a:nth-child(2) {
  margin-top: 0.5em;
}

@media screen and (min-width: 1200px) {
  .download-btns {
    flex-direction: row;
  }

  .download-btns a {
    width: 200px;
    height: 60px;
  }

  .download-btns a:nth-child(2) {
    margin-top: 0.14em;
  }
}
