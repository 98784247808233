.fqas-wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 4rem;
}
.fqas {
  width: 90%;
  margin: 3rem auto;
}
.fqas h1 {
  text-align: center;
  color: var(--purple);
}

.accordian-wrap {
  margin-top: 0.55rem;
}

/* Accordion styles */

.accordian {
  width: 50%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.55rem 0;
  border: 1px solid var(--smoke-white);
  border-radius: 0.55rem;
  margin-top: 0.8em !important;
}

.accordian-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0.85rem;
  margin-bottom: 0.12rem;
}

.accordian-heading .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordian-heading .container span {
  cursor: pointer;
  font-size: 1.3rem;
}

.accordian-content {
  padding: 1rem;
  transition: all 0.5s ease-out;
  /* display: none; */
}

@media (min-width: 100px) {
  .accordian {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    cursor: pointer;
    /* padding: 0.55rem 1.5rem; */
  }
}

@media (min-width: 550px) {
  .accordian {
    width: 80%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0.55rem 1rem;
  }
}

@media (min-width: 960px) {
  .accordian {
    width: 60%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0.55rem 1rem;
  }
}

@media (min-width: 1200px) {
  .accordian {
    width: 50%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0.55rem;
  }
}
