.tele-media-wrapper {
  padding: 2.5em 0;
  background: var(--bg);
}

.tele-media-section {
  width: 70%;
  margin: 0 auto;
}

.tele-media-section h2 {
  text-align: center;
  text-transform: capitalize;
  color: var(--purple);
  font-size: 1.3em;
  font-weight: 300;
}

.tele--media-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  margin: 1em 0;
  /* margin-top: 0.5em; */
}

.tele-media-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.strategy-offset-wrapper {
  width: 70%;
  margin: 0 auto;
}

.strategy-offset-section h2 {
  text-align: center;
  font-size: 2em;
  font-weight: 500;
  color: var(--purple);
  margin: 2em 0;
}

.strategy-offset-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  row-gap: 3em;
}

.strategy-offset-grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 1px solid var(--purple);
  border-radius: 30px;
}

.offset-strategy-count {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1.5em;
  font-weight: 400;
  color: var(--purple);
}
.strategy-offset-grid-item img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.offset-strategy-description {
  font-size: 1.3em;
  font-weight: 500;
  color: var(--text-color);
  text-align: center;
}

.offset-strategy-description span {
  color: var(--text-color-2);
}

@media screen and (max-width: 600px) {
  .tele--media-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
  }
}

@media screen and (min-width: 768px) {
  .tele--media-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
  }

  .strategy-offset-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
  }
}

@media screen and (min-width: 900px) {
  .tele--media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
  }
}

@media screen and (min-width: 1200px) {
  .tele-media-wrapper {
    padding: 0;
    padding-top: 2.5em;
  }

  .tele-media-section h2 {
    font-size: 2em;
  }

  .tele--media-grid {
    margin-top: -2em;
  }

  .tele-media-grid-item {
    height: 200px;
    width: 200px;
  }

  .strategy-offset-section h2 {
    font-size: 2.5em;
  }

  .strategy-offset-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3em;
  }

  .offset-strategy-description {
    font-size: 2em;
  }

  .strategy-offset-grid-item {
    padding: 1.5em;
  }

  .strategy-offset-grid-item img {
    height: 250px;
    width: 250px;
    object-fit: contain;
  }

  .strategy-offset-grid-item:nth-child(4) {
    flex-direction: row;
    grid-column: 1/4;
  }

  .strategy-offset-grid-item:nth-child(4) img {
    width: 500px;
    height: 150px;
  }

  .strategy-offset-grid-item:nth-child(4) .offset-strategy-description {
    margin-top: 0;
  }
}
