.blog-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  background: url("../../assets/img/Headersection/Header-Background-Image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-hero-section h1 {
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
}

.mobile-tags-section {
  /* display: none; */
  position: relative;
  margin-top: 2em;
  gap: 0.5em;
  display: flex;
  align-items: center;
}

.mobile-tags-section select {
  padding: 0.5em;
  flex: 0.8;
  appearance: none;
  color: var(--black);
  border: 0.1em solid var(--black);
  background: transparent;
  font-size: 0.9em;
}

.mobile-tags-section .custom-arrow {
  position: absolute;
  top: 25%;
  right: 15%;
  font-size: 1.2em;
}

.blog-tags {
  display: none;
  padding: 2em;
}

.blog-tags ul {
  display: flex;
  gap: 1.5em;
}

.blog-tags ul li {
  font-size: 1.1em;
  text-transform: capitalize;
  cursor: pointer;
  padding: 1em;
}

.active-tag {
  background: var(--black);
  color: var(--white);
  border-radius: 0.5em;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;
  padding: 2em 0;
}

/* MEDIA QUERIES */

@media screen and (min-width: 768px) {
  .mobile-tags-section .custom-arrow {
    right: 19%;
  }
}

@media screen and (min-width: 900px) {
  .mobile-tags-section {
    display: none;
  }

  .blog-tags {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .mobile-tags-section {
    display: none;
  }

  .blog-tags {
    display: block;
  }

  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
