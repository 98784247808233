footer {
  background: var(--dark);
}

.footer-container {
  width: 90%;
  margin: 0 auto;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem;
}

.footer-grid-1 .logo {
  width: 200px;
  margin-left: -0.8em;
}

.footer-grid-1 .logo img {
  width: 100%;
  object-fit: contain;
}

.footer-grid-1 .copyright {
  display: none;
  color: var(--white);
  line-height: 1.3;
  margin-top: 1rem;
  font-size: 0.95rem;
  /* width: 40%; */
}

.grid-header {
  color: var(--white);
  text-transform: capitalize;
  margin-top: 0.9rem;
}

.grid-item-list {
  color: var(--smoke-white);
  text-transform: capitalize;
  list-style: none;
}

.grid-item-list li {
  margin: 0.9rem 0;
  font-size: 0.9rem;
}

.footer-social-icons {
  display: flex;
  margin-top: 0.9rem;
}

.footer-social-icon {
  color: var(--white);
  font-size: 1.3rem;
  margin: 0;
}

.footer-social-icon:not(:first-child) {
  margin: 0 0.6rem;
}

.footer-social-icon:last-child {
  margin-right: 0;
}

.mobile-copyright {
  color: var(--white);
  font-size: 0.9rem;
  margin-top: 1rem;
}

@media screen and (min-width: 900px) {
  .footer-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .footer-grid-1 {
    grid-column: 1/3;
  }

  .footer-grid-1 .logo {
    width: 200px;
  }

  .footer-grid-1 .logo img {
    width: 100%;
    object-fit: contain;
  }

  .footer-grid-1 .copyright {
    display: block;
  }

  .mobile-copyright {
    display: none;
  }
}
