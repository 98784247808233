.blog-detail-wrapper {
  padding-top: 30%;
  padding-bottom: 2em;
}

.breadcrumb ul {
  display: flex;
  gap: 0.3em;
}

.breadcrumb ul li {
  color: var(--gray);
  font-size: 0.9em;
}

.breadcrumb ul li a {
  text-transform: capitalize;
  color: var(--gray);
}

.breadcrumb ul li:first-child::after {
  content: ">";
  margin-left: 0.2em;
}

.breadcrumb ul li:first-child a {
  text-decoration: underline;
}

/* .breadcrumb ul li:nth-child(2) {
  color: #cecece;
} */

.blog-header {
  text-align: center;
  text-transform: capitalize;
}

.blog-date {
  font-size: 1.3em;
  margin: 0.7em 0;
}

.blog-header h1 {
  font-size: 2em;
  /* width: 60%; */
  margin: 0 auto;
}

.blog-detail-img {
  height: 20em;
  margin: 2em auto;
  /* width: 60%; */
}

.blog-detail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8em;
}

.blog-content {
  /* width: 50%; */

  margin: 1em auto;
}

.blog-content p {
  font-size: 1em;
  line-height: 1.5;
  margin: 1em 0;
}

/* MEDIA QUERIES */
@media screen and (min-width: 1200px) {
  .blog-detail-wrapper {
    padding-top: 12%;
  }

  .breadcrumb ul li {
    font-size: 1em;
  }

  .blog-header h1 {
    font-size: 3em;
    width: 60%;
  }

  .blog-detail-img {
    width: 60%;
    height: 30em;
  }

  .blog-content {
    width: 50%;
  }

  .blog-content p {
    font-size: 1.2em;
  }
}
