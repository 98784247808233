/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

body {
  line-height: 1.2;
}

/* css reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
  /* border: 1px solid red; */
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: #000;
  --smoke-white: hsl(150, 7%, 82%);
  --light-gray: #f7f7f7;
  --text-color: hsl(0, 0%, 38%);
  --text-color-2: hsl(75, 100%, 32%);
  --bg: hsl(0, 0%, 95%);
  --dark: hsl(0, 0%, 7%);
  --dark-light: hsl(0, 0%, 9%);
  --purple: hsl(274, 53%, 37%);
  --gray: #cecece;
  --gray-2: #f7f7f7;
  --gray-3: #d1d1d1;
}

/* utility style */
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--black-color);
}
img {
  width: 100%;
}
