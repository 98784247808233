.hero-section {
  display: flex;
  justify-content: center;
  padding-top: 33%;
  background: url("../../assets/img/Headersection/Header-Background-Image.png");
  background-position: center center;
  background-size: cover;
  color: var(--white);
  overflow-x: hidden;
}

.hero-content {
  width: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.hero-content h1 {
  text-align: center;
  font-size: 2em;
}

.hero-content h1::first-letter {
  text-transform: uppercase;
}

.hero-content p {
  text-align: center;
  font-size: 0.9em;
  margin: 0.9rem 0;
}

.hero-content p br {
  display: none;
}

.mockup-img {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1.5em;
}

@media screen and (max-width: 600px) {
  .mockup-img {
    position: relative;
    right: -18%;
  }
}

@media screen and (min-width: 768px) {
  .mockup-img {
    position: relative;
    right: -20%;
  }
}

@media screen and (min-width: 900px) {
  .hero-section {
    padding-top: 18%;
  }

  .hero-content h1 {
    font-size: 2.5em;
  }

  .mockup-img {
    position: relative;
    right: -16%;
  }
}

@media screen and (min-width: 1200px) {
  .hero-section {
    padding-top: 10%;
  }

  .hero-content {
    gap: 2em;
  }

  .hero-content h1 {
    font-size: 3em;
  }

  .hero-content p {
    font-size: 1em;
    margin: 0.2em auto;
    text-align: justify;
  }

  .hero-content p br {
    display: block;
  }

  .mockup-img {
    position: static;
    margin-left: 13.5rem;
  }
}
